import React from 'react';

function Contact({data})
{
    const {title, description, gmap, location, email, phone} = data || {};
    return(
        <div className="contact-form">
            <h4 className="sectionHeader">contact us</h4>
            <h1 className="heading">{title}</h1>
            <p className="para">{description}</p>
            
            <div className="contactForm" >
                <form action="#">
                    <h1 className="sub-heading">Need Support !</h1>
                    <p className="para para2">Contact us for a quote , help to join the them.</p>
                    <input type="text" className="input" placeholder="your name" />
                    <input type="text" className="input" placeholder="your email" />
                    <input type="text" className="input" placeholder="your Subject" />
                    <textarea className="input" cols="30" rows="5" placeholder="Your message..."></textarea>
                    <input type="submit" className="input submit" value="Send Message" />
                </form>

                <div className="map-container">
                    <div className="mapBg"></div>
                    <div className="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.509345942155!2d73.71281237877422!3d18.596146537420758!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bbb98e08c485%3A0x88605aadb7c213a6!2sGera&#39;s%20Imperium%20Rise!5e0!3m2!1sen!2sin!4v1726385275566!5m2!1sen!2sin" width="600" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>

            <div className="contactMethod">
                <div className="method">
                    <i className="fa-solid fa-location-dot contactIcon"></i>
                    <article className="texxt">
                        <h1 className="sub-heading">Location</h1>
                        <p className="para">{location}</p>
                    </article>
                </div>

                <div className="method">
                    <i className="fa-solid fa-envelope contactIcon"></i>
                    <article className="texxt">
                        <h1 className="sub-heading">Email</h1>
                        <p className="para">Email: {email}</p>
                    </article>
                </div>

                <div className="method">
                    <i className="fa-solid fa-phone contactIcon"></i>
                    <article className="texxt">
                        <h1 className="sub-heading">Phone</h1>
                        <p className="para">{phone}</p>
                    </article>
                </div>
            </div>
        </div> 
    );
}

export default Contact;
