import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import './firebaseConfig';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import Navbar from './components/Navbar';
import Header from './components/Header';
import Client from './components/Clients';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  let [v1, setV1] = useState([]);
  const db = getFirestore();

  const fetchData = async ()=>{
    const snapShot = await getDocs(collection(db, 'index'));
    const tempArray = {};
    snapShot.forEach((doc)=>{
      //tempArray.push(doc.data());
      tempArray[doc.id] = doc.data();
    });
    setV1(tempArray);
    //console.log(tempArray);
  }

  useEffect(()=>{
    fetchData();
  }, []);

  console.log(v1);

  return (
    <div>
      <Navbar />
      <Header data={v1['header']}/>
      <Client />
      <About data={v1['about']}/>
      <Services data={v1['services']}/>
      <Contact data={v1['contact']} />
      <Footer  data={v1['footer']} />
    </div>
  );
}

export default App;
