import React from 'react';

function Footer({data})
{
    const {title, description} = data || {};
    return(
        <footer className="new_footer_area bg_color">
            <div className="new_footer_top">
                <div className="container">
                    <div className="row" style={{'display':'flex','justifyContent': 'spaceBetween','flexWrap': 'wrap'}}>
                    <div className="conttent">
                        <div className="f_widget company_widget wow fadeInLeft" data-wow-delay="0.2s" style={{'visibility': 'visible', 'animationDelay': '0.2s', 'animationName': 'fadeInLeft'}}>
                        <h3 className="f-title f_600 t_color f_size_18">{title}</h3>
                        <p style={{'maWidth': '500px'}}>{description}</p>
                        
                    </div>
                    </div>
                    <div className="conttent">
                        <div className="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.4s" style={{'visibility': 'visible', 'animationDelay': '0.4s', 'animationName': 'fadeInLeft'}}>
                        <h3 className="f-title f_600 t_color f_size_18">Navigate</h3>
                        <ul className="list-unstyled f_list">
                            <li><a href="#">About</a></li>
                            <li><a href="#">Services</a></li>
                            <li><a href="#">Contact us</a></li>
                            
                        </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.6s" style={{'visibility': 'visible', 'animationDelay': '0.6s', 'animationName': 'fadeInLeft'}}>
                        <h3 className="f-title f_600 t_color f_size_18">Help</h3>
                        <ul className="list-unstyled f_list">
                            <li><a href="#">Term &amp; conditions</a></li>
                            <li><a href="#">Support Policy</a></li>
                            <li><a href="#">Privacy</a></li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="f_widget social-widget pl_70 wow fadeInLeft" data-wow-delay="0.8s" style={{'visibility': 'visible', 'animationDelay': '0.8s', 'animationName': 'fadeInLeft'}}>
                        <h3 className="f-title f_600 t_color f_size_18">Connect with us</h3>
                        <div className="f_social_icon">
                            <a href="#" className="fab fa-facebook"></a>
                            <a href="#" className="fab fa-twitter"></a>
                            <a href="#" className="fab fa-instagram"></a>
                    
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="footer_bg">
                    <div className="car_bx">
                        <div className="car" style={{maxWidth: '200px'}}>
                            <img style={{width: '200px'}} src="./img/Green_Travel_Eco_Friendly_Initiatives_in_the_Airport_Cab_Sector-Photoroom.png" alt="" />
                            <div className="wheel" style={{maxWidth: '30px'}}>
                                <img style={{'width': '30px', 'position': 'absolute','top':'96px','left':'27px'}} src="./img/wheel-Photoroom.png" alt="" className="front_w" />
                                <img style={{'width': '30px', 'position': 'absolute','top':'96px','right':'33px'}} src="./img/wheel-Photoroom.png" alt="" className="rare_w" />
                            </div>
                        </div>
                    </div>

                    <div className="footer_bg_two">

                    </div>
                </div>
            </div>
            <div className="footer_bottom">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-sm-7">
                            <p className="mb-0 f_400">© Shreej Enterprises 2024 All rights reserved.</p>
                        </div>
                
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
