import React from 'react';

function Header({data}){
    const {description, slogan} = data || {};
    return (
        <header>
            <div className="container header-container">
                <div className="header-left">
                    <h1>SHREEJ ENTERPRISES</h1>
                    <h3>{slogan}</h3>
                    <p>
                        {description}
                    </p>
                    <a href="" className="btn">Wanna Know More</a>
                </div>
                <div className="header-right">
                    <div className="sq-box">
                        <img src="img/carcab-min-Photoroom.png" alt="" />
                    </div>
                </div>
            </div>
            <div className="sq-box2"></div>
        </header>
    );
}

export default Header;
