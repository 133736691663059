import React from 'react';

function Services({data})
{
    //console.log(data);
    const {description} = data || {};
    const services = data?.services || {};
    //const services = data['services'];
    console.log(services);
    Object.entries(services).map(([key, value])=>(
        console.log(value.title)
    ))
    return(
        <div className="c-section" style={{position: 'relative'}}>
            <h2 className="c-section__title"><span>OUR SERVICES<p>{description}</p></span></h2>
            <img className="service_img" src="img/svg1.png" alt="" />
            <ul className="c-services">
                {Object.entries(services).map(([key, value])=>(
                    <li className="c-services__item" data-aos="flip-right" key={key}>
                        <h3>{value.title}</h3>
                        <p>{value.description}</p>
                    </li>
                ))}
                
                {/* <li className="c-services__item" data-aos="flip-right">
                    <h3>Corporate Car Rentals</h3>
                    <p>Elevate your business travel with our corporate car rentals. Shreej Enterprises offers a fleet of high-quality vehicles tailored to meet your corporate needs, ensuring a premium travel experience for your executives and clients.</p>
                </li>
                <li className="c-services__item" data-aos="flip-left">
                    <h3>Electric Car Services Support on ESG Initiatives</h3>
                    <p>Support your ESG initiatives with our electric car services. We provide eco-friendly transportation solutions that align with your sustainability goals, helping your business reduce its carbon footprint while maintaining top-notch service.</p>
                </li>
                <li className="c-services__item" data-aos="flip-right">
                    <h3>Employee Transportation Services</h3>
                    <p>Our employee transportation services are designed to provide reliable and comfortable travel for your staff. With a focus on punctuality and efficiency, we handle all aspects of employee transport with professionalism and care.</p>
                </li>
                <li className="c-services__item" data-aos="flip-left">
                    <h3>Ambulance Services</h3>
                    <p>Our reliable ambulance services ensure rapid response and professional care in emergencies. With experienced staff and well-equipped vehicles, Shreej Enterprises is committed to providing prompt and effective medical transportation.</p>
                </li>
                <li className="c-services__item" data-aos="flip-right">
                    <h3>End-to-End Employee Transport</h3>
                    <p>Shreej Enterprises offers seamless end-to-end employee transport solutions, ensuring timely and safe commutes from start to finish. Our dedicated team manages every detail to keep your workforce mobile and productive.</p>
                </li> */}
            </ul>
        </div>
    );
}

export default Services;
