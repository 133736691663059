import React from 'react';
import './css/navbar.css';

function Navbar()
{
    const navLinkStyleObject1 = {
        '--i': '1'
    };
    const navLinkStyleObject2 = {
        '--i': '2'
    };
    const navLinkStyleObject3 = {
        '--i': '3'
    };
    const navLinkStyleObject4 = {
        '--i': '4'
    };

    return(
        <nav className="nav_tank">
            <div className="container nav-container">
                <a href="" className="logo"><h3 className="l_name">Shreej Enterprises</h3></a>
                <div className="menu-icon" id="menu-icon">
                  <i className="fas fa-bars " ></i>
                  <i className="fas fa-times close-icon" style={{display: 'none'}}></i> 
              </div>

                <ul className="nav-link">
                    <li><a href="" style={navLinkStyleObject1} className="active">Home</a></li>
                    <li><a href="" style={navLinkStyleObject2}>About</a></li>
                    <li><a href="" style={navLinkStyleObject3}>Services</a></li>
                    <li><a href="" style={navLinkStyleObject4}>Contact</a></li>
                </ul>
                <ul className="social-link">
                    <li><a href="" style={navLinkStyleObject1}><i className="fab fa-facebook"></i></a></li>
                    <li><a href="" style={navLinkStyleObject2}><i className="fab fa-twitter"></i></a></li>
                    <li><a href="" style={navLinkStyleObject3}><i className="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;
