import React from 'react';

function Client()
{
    return(
        <div className="slider-area">
          <h2 className="a_title">Our Clients</h2>
          <div className="wrapper">
         
            <div className="item"><img alt="" src="img/clients/synechron-Photoroom.png" /></div>
            <div className="item"><img alt="" src="img/clients/tech_mahindra.png" /></div>
            <div className="item"><img alt="" src="img/clients/Cognizant-Photoroom.png" /></div>
            <div className="item"><img alt="" src="img/clients/wip-Photoroom.png" /></div>
            <div className="item"><img alt="" src="img/clients/atos-Photoroom.png" /></div>
            <div className="item"><img alt="" src="img/clients/infosys-Photoroom.png" /></div>
            <div className="item"><img alt="" src="img/clients/orchild-Photoroom.png" /></div>
            <div className="item"><img alt="" src="img/clients/hcl-Photoroom.png" /></div>

            <div className="item"><img alt="" src="img/clients/synechron-Photoroom.png" /></div>
            <div className="item"><img alt="" src="img/clients/tech_mahindra.png" /></div>
            <div className="item"><img alt="" src="img/clients/Cognizant-Photoroom.png" /></div>
            <div className="item"><img alt="" src="img/clients/wip-Photoroom.png" /></div>
            <div className="item"><img alt="" src="img/clients/atos-Photoroom.png" /></div>
            <div className="item"><img alt="" src="img/clients/infosys-Photoroom.png" /></div>
            <div className="item"><img alt="" src="img/clients/orchild-Photoroom.png" /></div>
            <div className="item"><img alt="" src="img/clients/hcl-Photoroom.png" /></div>
          </div>
          
        </div>
    );
}

export default Client;
