import React from 'react';

function About({data})
{
    const {description, mission, vision} = data || {}
    return(
        <div className="responsive-container-block bigContainer">
            <h2 className="a_title" style={{'textAlign': 'center', 'fontFamily': 'impact','fontSize': '60px','fontWeight': '500','textTransform': 'uppercase','letterSpacing': '2px','margin': '30px 0 30px 0','color': '#399918'}}>About Us</h2>
            <div className="responsive-container-block Container bottomContainer">
                <div className="ultimateImg">
                    <img className="mainImg" src="https://www.gogreencabs.com/wp-content/uploads/2022/09/WhatsApp-Image-2022-09-26-at-4.43.24-PM-1024x402.jpeg" />
                    <div className="p_Box p1">
                        <h2 className="p_title">Our Mission</h2>
                        <p className="p_Text">{mission}</p>
                    </div>
                    <div className="p_Box p2">
                        <h2 className="p_title">Our Vision</h2>
                        <p className="p_Text">{vision}</p>
                    </div>
                </div>
                <div className="allText bottomText">
                    <p className="text-blk headingText">About Us</p>
                    <p className="text-blk subHeadingText">We are a Transport Solutions Company Based in India</p>
                    <p className="text-blk description">{description}</p>                
                </div>
            </div>
        </div>
    );
}

export default About;
